<template>
    <div class="fixed flex flex-col right-0 bg-white h-screen w-96 z-50 shadow-xl border-l-4 border-black px-6">
        <div class="h-56">
            <div class="mt-12 text-2xl pb-3 border-b-8 w-40 border-gray-700">
                Shopping cart
            </div>
        </div>
        <div class="flex-1 space-y-12">
            <div class="flex flex-col items-start" v-if="store.cornDog">
                <div class="flex items-center justify-between w-full">
                    <div>Back-End</div>
                    <div v-text="`${store.cornDog}x`"/>
                </div>
                <div class="flex justify-end w-full" v-text="`${Math.round(store.cornDog * 2.99 * 100) / 100}$`" />
            </div>
            <div class="flex flex-col items-start" v-if="store.miniCornDog">
                <div class="flex items-center justify-between w-full">
                    <div>Front-End</div>
                    <div v-text="`${store.miniCornDog}x`"/>
                </div>
                <div class="flex justify-end w-full" v-text="`${Math.round(store.miniCornDog * 2.59 * 100) / 100}$`" />
            </div>
            <div class="flex flex-col items-start" v-if="store.hamburger">
                <div class="flex items-center justify-between w-full">
                    <div>Autre Aptitude</div>
                    <div v-text="`${store.hamburger}x`"/>
                </div>
                <div class="flex justify-end w-full" v-text="`${Math.round(store.hamburger * 4.99  * 100) / 100}$`" />
            </div>

            <div class="border-t-2 border-black flex items-center justify-between font-bold pt-9">
                <div>Total</div>
                <div v-text="`${Math.round((this.store.miniCornDog * 2.59 * 100) + (this.store.cornDog * 2.99 * 100) + (this.store.hamburger * 4.99 * 100)) / 100}$`" />
            </div>
        </div>

        <div class="flex items-center justify-center h-32">
            <button class="px-8 py-4 bg-gray-700 text-white rounded-full shadow-xl">
                Payer
            </button>
        </div>
    </div>
</template>

<script>
import { useCounterStore } from '../Store/cart';

export default {
    setup() {
        const store = useCounterStore();

        return { store };
    },

    name: 'Cart',

    props: {
        shopOpened: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
