import Vue from 'vue';
import './assets/styles/index.css';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart, faCoffee, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AOS from 'aos';
import { createPinia, PiniaPlugin } from 'pinia';
import App from './App.vue';
import 'aos/dist/aos.css';
import router from './router/index';

Vue.use(PiniaPlugin);
const pinia = createPinia();

library.add(faShoppingCart, faCoffee, faArrowDown);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false; // You can also use <link> for styles
AOS.init();

new Vue({
    render: (h) => h(App),
    pinia,
    router,
}).$mount('#app');
