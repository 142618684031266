import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/Home.vue';
import Wordgame from '../components/games/Wordgame.vue';
import Unauthorized from '../components/Unauthorized.vue';
import Privacy from '../components/Privacy.vue';

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/index.html',
        name: 'home',
        component: Home,
    },
    {
        path: '/sizzle',
        name: 'sizzle',
        component: Wordgame,
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
    },
    {
        path: '*',
        name: 'Unauthorized',
        component: Unauthorized,
    },
];

const router = new Router({
    mode: 'history',
    routes,
});

Vue.router = router;

export default router;
