<template>
    <div class="h-screen overflow-hidden flex items-center text-2xl flex-col justify-center">
        Sizzle: Trouvez le mot du jour. Est maintenant  ici <a class="text-blue-500" href="https://www.sizzle.jonathancleary.ca">www.sizzle.jonathancleary.ca</a>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: 'App',

    components: {},

    data() {
        return {
            dictionary: ['test'],
            answer: '',
            rowLineCount: 1,
            cursorPos: 1,
            filledCount: 1,
            victory: false,
            tempTryRow: [],
            gameRows: [],
            keyboard: [
                ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
                ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
                ['Z', 'X', 'C', 'V', 'B', 'N', 'M', '-', "'", '.'],
            ],
        };
    },

    methods: {
        deleteKey() {
            if (this.cursorPos - 1 === 0) {
                return;
            }

            Vue.set(this.tempTryRow, this.cursorPos - 1, '');

            if (this.cursorPos <= 1) {
                return;
            }

            this.filledCount--;
            this.cursorPos--;
        },

        clickKey(key) {
            if (this.cursorPos >= this.rowLineCount) {
                return;
            }

            Vue.set(this.tempTryRow, this.cursorPos, key);

            if (this.cursorPos > this.rowLineCount - 1) {
                return;
            }

            this.filledCount++;
            this.cursorPos++;
        },

        keyClasses(result) {
            switch (result) {
                case 'good': return 'bg-green-500';
                case 'bad': return 'bg-gray-100';
                case 'close': return 'bg-yellow-500';
                default: return '';
            }
        },

        lineIsCompleted(rowIndex) {
            return !!this.gameRows[rowIndex];
        },

        confirmLine() {
            if (this.filledCount !== this.rowLineCount) {
                return;
            }

            if (this.tempTryRow.join('') === this.answer) {
                this.victory = true;

                return;
            }

            this.generateAConfirmedRow();
            // put cursor at 1
        },

        generateNewEmptyRow() {
            const row = new Array(this.rowLineCount).fill(null);
            // eslint-disable-next-line prefer-destructuring
            row[0] = this.answer[0];
            this.cursorPos = 1;
            this.tempTryRow = row;
        },

        generateAConfirmedRow() {
            const row = [{ key: this.answer[0], result: 'good' }];

            for (let i = 1; i < this.rowLineCount; i++) {
                const object = { key: this.tempTryRow[i], result: 'bad' };

                if (this.tempTryRow[i] === this.answer[i]) {
                    object.result = 'good';
                }

                row.push(object);
            }

            this.gameRows.splice(this.gameRows.length, 0, row);
            this.generateNewEmptyRow();
            this.filledCount = 1;
        },
    },
    beforeMount() {
        const dictLength = (this.dictionary).length;
        const indexRandom = Math.floor(Math.random() * dictLength);
        this.answer = (this.dictionary[indexRandom]).toUpperCase();

        // CLEAN UP DICTIONARY
        // const newArray = [];
        // for (let i = 0; i < dictLength; i++) {
        //     const data = this.dictionary[i];
        //
        //     if (data.length < 5 || data.length > 12) {
        //         // eslint-disable-next-line no-continue
        //         continue;
        //     }
        //
        //     if (data.includes(' ') || data.includes('Í') || data.includes('‚') || data.includes('Ú') || data.includes('W') || data.includes('È') || data.includes('Ó') || data.includes('Ë') || data.includes('Ô')) {
        //         // eslint-disable-next-line no-continue
        //         continue;
        //     }
        //
        //     if (data[data.length - 1] === 'S') {
        //         // eslint-disable-next-line no-continue
        //         continue;
        //     }
        //
        //     newArray.push(data.toUpperCase());
        // }
        // console.log(newArray);
        this.rowLineCount = (this.answer).length;

        this.generateNewEmptyRow();
    },
};
</script>
