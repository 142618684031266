<template>
    <div class="relative home-page">
        <div v-show="false" class="full">
            <div>
                <p class="wip">Work In Progress</p>
                <a href="mailto:clearyjonathan99@gmail.com" class="email">clearyjonathan99@gmail.com</a>
            </div>
        </div>

        <rocket />

        <transition
            enter-active-class="transition duration-300 transform"
            enter-class="opacity-0 translate-x-full"
            enter-to-class="opacity-100 translate-x-0"
            leave-active-class="transition duration-300 transform"
            leave-class="opacity-100 translate-x-0"
            leave-to-class="opacity-0 translate-x-full"
            mode="out-in"
        >
            <cart v-if="shopOpened" />
        </transition>

        <div class="fixed z-50 w-full flex items-end justify-end">
            <div class="bg-white m-4 h-12 flex items-center justify-center rounded-full shadow-xl cursor-pointer | transition-all duration-300" :class="total > 0 ? 'w-auto px-3' : 'w-12'" @click="openShop">
                <font-awesome-icon class="text-2xl" icon="shopping-cart" />
                <div v-if="total !== 0" class="ml-2" v-text="`${total}$`" />
            </div>
        </div>
        <Header :shop-opened.sync="shopOpened" />
    </div>
</template>
<script>
import Header from './Header.vue';
import Rocket from './Rocket.vue';
import Cart from './Cart.vue';
import { useCounterStore } from '@/Store/cart';

export default {
    setup() {
        const store = useCounterStore();

        return { store };
    },

    name: 'Home',
    components: {
        Header,
        Cart,
        Rocket,
    },
    data() {
        return {
            shopOpened: false,
        };
    },
    methods: {
        openShop() {
            this.shopOpened = !this.shopOpened;
        },
    },
    computed: {
        total() {
            return Math.round((this.store.miniCornDog * 2.59 * 100) + (this.store.cornDog * 2.99 * 100) + (this.store.hamburger * 4.99 * 100)) / 100;
        },
    },
};
</script>
<style>
.full {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}
.email {
  color: rgb(193, 164, 128);
  text-decoration: none;
  font-size: 20px;
}
.wip {
  text-decoration: italic;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Nunito:wght@200&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
