<template>
    <div class="absolute z-30 pointer-events-none w-full | flex items-center justify-center">
        <div class="w-full h-screen" id="container3d" />
    <!--                    <img class="w-2/3 pointer-events-none | lg:w-auto" data-scroll-speed="4" src="../assets/img/bloody.png" alt="">-->
    </div>
</template>
<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default {
    data() {
        return {
            scrollElement: null,
            oldScrollPos: 0,
            mesh: null,
            rot: 0,
            scene: null,
            camera: null,
            renderer: null,
            previousMousePosition: {
                x: 0,
                y: 0,
            },
            isDragging: false,
        };
    },

    methods: {
        isScrolling() {
            const oneVhHeight = window.innerHeight;
            const scrollPos = this.scrollElement.scrollTop;
            let isUpScroll = false;

            if (this.oldScrollPos > scrollPos) {
                isUpScroll = true;
            }

            if (scrollPos === oneVhHeight || scrollPos === oneVhHeight * 2 || scrollPos === oneVhHeight * 3) {
                return;
            }

            if (scrollPos < oneVhHeight) {
                this.mesh.rotation.y += isUpScroll ? -0.06 : 0.06;
                this.mesh.position.z += isUpScroll ? 0.020 : -0.020;
                this.mesh.position.x += isUpScroll ? -0.010 : 0.010;
            } else if (scrollPos < oneVhHeight * 2) {
                this.mesh.rotation.z += isUpScroll ? 0.030 : -0.030;
                this.mesh.position.z += isUpScroll ? 0.010 : -0.010;
            } else if (scrollPos < oneVhHeight * 3) {
                this.mesh.position.z += isUpScroll ? -0.010 : 0.010;
                this.mesh.position.x += isUpScroll ? 0.024 : -0.024;
                this.mesh.position.z += isUpScroll ? 0.010 : -0.010;
                this.mesh.rotation.z += isUpScroll ? -0.010 : 0.010;
            } else if (scrollPos < oneVhHeight * 4) {
                this.mesh.position.x += isUpScroll ? -0.01 : 0.01;
                this.mesh.position.z += isUpScroll ? 0.015 : -0.015;
            }

            this.oldScrollPos = scrollPos;
        },

        initThree() {
            const container = document.getElementById('container3d');

            this.camera = new THREE.PerspectiveCamera(50, container.clientWidth / container.clientHeight, 0.01, 10);
            this.camera.position.z = 1;

            this.scene = new THREE.Scene();

            const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
            hemiLight.position.set(0, 400, 0);
            this.scene.add(hemiLight);

            this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            this.renderer.setSize(container.clientWidth, container.clientHeight);
            this.renderer.setClearColor(0x000000, 0);

            container.appendChild(this.renderer.domElement);

            const loader = new GLTFLoader();
            loader.load('objects/donut/Space_rocket.glb', (object) => {
                this.mesh = object.scene;

                if (this.isSmall) {
                    this.mesh.position.x = 0.2;
                    this.mesh.scale.set(0.02, 0.02, 0.02);
                    this.mesh.position.y = -0.05;
                } else {
                    this.mesh.position.x = 0.5;
                    this.mesh.scale.set(0.02, 0.02, 0.02);
                    this.mesh.position.y = -0.2;
                }

                // this.mesh.rotation.x = 2;
                // this.mesh.rotation.set(10, 0, 0);
                this.scene.add(this.mesh);
                this.renderer.render(this.scene, this.camera);
            });

            // container.addEventListener('mousedown', () => {
            //     this.isDragging = true;
            // });
            //
            // container.addEventListener('mouseup', () => {
            //     this.isDragging = false;
            // });
            //
            // container.addEventListener('mousemove', (e) => {
            //     const deltaMove = {
            //         x: e.offsetX - this.previousMousePosition.x,
            //         y: e.offsetY - this.previousMousePosition.y,
            //     };
            //
            //     if (this.isDragging) {
            //         const deltaRotationQuaternion = new THREE.Quaternion()
            //             .setFromEuler(new THREE.Euler(0, THREE.MathUtils.degToRad(deltaMove.x), 0, 'XYZ'));
            //         this.mesh.quaternion.multiplyQuaternions(deltaRotationQuaternion, this.mesh.quaternion);
            //     }
            //
            //     this.previousMousePosition = { x: e.offsetX, y: e.offsetY };
            // });
        },

        animateThree() {
            requestAnimationFrame(this.animateThree);
            if (!this.mesh) {
                return;
            }

            if (!this.isDragging) {
                this.mesh.rotation.y += 0.005;
            }

            this.renderer.render(this.scene, this.camera);
        },
    },

    computed: {
        isSmall() {
            return window.innerWidth < 600;
        },
    },

    mounted() {
        this.initThree();
        this.animateThree();
        this.scrollElement = document.getElementById('main-frame');
        this.scrollElement.addEventListener('scroll', this.isScrolling);
    },
};
</script>
