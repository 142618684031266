import { defineStore } from 'pinia';

// eslint-disable-next-line import/prefer-default-export
export const useCounterStore = defineStore('cart', {
    state: () => ({
        cornDog: 0,
        miniCornDog: 0,
        hamburger: 0,
    }),

    getters: {
        getCornDog(state) {
            return state.cornDog;
        },
        getMiniCornDog(state) {
            return state.miniCornDog;
        },
        getHamburger(state) {
            return state.hamburger;
        },
    },

    actions: {
        addCornDog() {
            this.cornDog++;
        },
        addMiniCornDog() {
            this.miniCornDog++;
        },
        addHamburger() {
            this.hamburger++;
        },

        removeCornDog() {
            this.cornDog--;
        },
        removeMiniCornDog() {
            this.miniCornDog--;
        },
        removeHamburger() {
            this.hamburger--;
        },
    },
});
