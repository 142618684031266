<template>
    <div class="px-0 mb-8 lg:mb-20 w-full | md:px-6 xl:w-1/3 relative shadow-lg z-40">
        <div class="bg-x2 border-2 border-x5 text-x5 rounded-xl w-full relative">
            <transition
                enter-active-class="transition duration-150 transform"
                enter-class="opacity-0 -translate-y-2"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition duration-150 transform"
                leave-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-2"
                mode="out-in"
            >
                <div class="rounded-full bg-white shadow px-4 h-10 -mt-6 -mr-4 absolute right-0 flex items-center justify-center text-black font-bold" v-text="`${priceTotal}$ (${itemsCount})`" v-if="itemsCount > 0" />
            </transition>
            <div class="flex justify-between items-center p-6">
                <div class="text-3xl font-medium | md:max-w-auto bg-x5 px-4 py-2 text-white shadow transform -rotate-3 -translate-y-10" v-text="title" />
            </div>
            <div class="h-12 lg:h-28 relative flex items-center justify-center | md:h-48">
                <div style="filter: blur(80px)" class="absolute shadow-md h-56 w-56 top-0 ml-0 bg-opacity-50 z-0 left-0 rounded-full | md:ml-32" :class="color" />
                <img class="relative z-30 pointer-events-none" :class="classes" :src="require(`@/assets/img/${image}.png`)" alt="">
            </div>
            <div class="text-xl my-10 flex items-center lg:flex-col space-x-4 lg:space-x-0 lg:space-y-2">
                <slot name="description"></slot>
            </div>
            <div class="py-1 min-h-20 text-xl" v-text="description" v-if="description" />
            <div class="flex flex-col items-end | md:items-center md:flex-row">
                <div class="flex items-center w-full mb-4 justify-center | md:mb-10">
                    <button class="py-4 px-6 rounded-full bg-white text-xl text-x5 shadow | transition duration-300 | hover:bg-gray-300" @click="$emit('add')">Ajouter au panier</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCounterStore } from '../../Store/cart';

export default {
    setup() {
        const store = useCounterStore();

        return { store };
    },

    props: {
        itemsCount: {
            type: Number,
            default: 0,
        },
        title: {
            type: String,
            default: 'Front End',
        },
        description: {
            type: String,
            default: '',
        },
        price: {
            type: Number,
            default: 2.99,
        },
        image: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'bg-yellow-400',
        },
        classes: {
            type: String,
            default: 'w-full',
        },
    },

    computed: {
        priceTotal() {
            return Math.round(this.price * this.itemsCount * 100) / 100;
        },
    },
};
</script>
