<template>
    <div id="main-frame" class="bg-x2 snap-main">
        <section class="snap-section space-bg overflow-hidden">
            <div class="px-16 flex justify-center h-full lg:justify-between items-center relative bg-x2 cover-up | flex-row">
                <div class="h-56 | lg:h-auto">
                    <div class="font-bold text-x2 text-enter-animation anim relative text-3xl | lg:text-9xl">
                        <span class="text-wrapper relative inline-block z-20">
                            <span class="letters">Jonathan Cleary</span>
                        </span>
                    </div>
                    <div class="text-2xl mt-2 | lg:text-6xl anim2 relative text-left">
                        <span class="text-wrapper relative inline-block overflow-hidden">
                            <span class="letters2 text-x2">Développeur full-stack.</span>
                        </span>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1000" data-scroll-speed="6" class="absolute shadow-md mr-2 mt-28 right-0 z-0 h-40 w-40 float-right rounded-full bg-x5 | lg:mt-0 lg:mr-56 lg:h-56 lg:w-56" />
                <div data-aos="fade-up" data-aos-duration="1000" data-scroll-speed="8" class="absolute shadow-md ml-2 z-0 h-12 mt-32 top-0 w-12 float-right rounded-full bg-x5 | lg:mt-60 lg:ml-24 lg:h-32 lg:w-32" />

                <div data-scroll-speed="3" class=" absolute z-0 w-full bottom-0 left-0 float-right bg-x5" />

                <transition
                    enter-active-class="transition duration-1000"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition duration-1000"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                    mode="out-in"
                >
                    <div class="w-full absolute mb-32 lg:mb-48 bottom-0 flex items-center justify-center flex-col lg:mb-32 text-x2" v-if="!isScrolled">
                        <div class="mb-4 font-extralight text-2xl | lg:text-3xl">Défiler</div>
                        <font-awesome-icon class="text-3xl animate-bounce" icon="arrow-down" />
                    </div>
                </transition>
            </div>
            <!--            <svg class="z-0 relative" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#260104" fill-opacity="1" d="M0,64L80,69.3C160,75,320,85,480,80C640,75,800,53,960,53.3C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>-->
        </section>
        <section class="snap-section flex items-center justify-center">
            <div class="px-10 flex-1 flex flex-col items-center justify-center">
                <div data-aos="fade-up" data-aos-duration="300" class="flex w-full lg:w-2/3 text-3xl lg:text-7xl font-bold mb-12">À propos</div>
                <p data-aos="fade-up" data-aos-duration="500" class="text-left w-full lg:w-2/3 lg:text-lg text-sm">Je suis un développeur avec une bonne connaissance des enjeux technologiques. J'ai travaillé à créer des outils internes pour améliorer l'efficacité des départements ainsi que mis sur pieds des microservices modernes.</p>
                <p data-aos="fade-up" data-aos-duration="750" class="text-left w-full lg:w-2/3 mt-6 lg:text-lg text-sm">J'ai développé des API robustes pour recevoir et envoyer du data avec plusieurs third partys générant <span class="text-x5 font-semibold">des millions de requête annuellement.</span></p>
                <p data-aos="fade-up" data-aos-duration="1000" class="text-left w-full lg:w-2/3 mt-6 lg:text-lg text-sm">Nommé Chef technique et responsable UI/UX, j’ai aussi été amené à développer des tests efficaces et développer ma pensé critique pour résoudre différents problèmes de programmation complexes.</p>
            </div>
            <div class="flex-1 relative z-40">
                <img data-aos="fade-up" data-aos-duration="1500" src="@/assets/img/astronaut_me.webp" alt="">
            </div>
        </section>

        <section class="snap-section bg-x4 relative text-white pb-32 | lg:px-40">
            <div class="absolute mr-0 bottom-0 right-0 mb-80 z-0 h-56 w-56 rounded-full bg-x5" />
            <div class="absolute mr-20 lg:mr-96 mb-28 top-0 right-0 z-0 h-12 w-12 rounded-full bg-x5" />
            <div style="mix-blend-mode: screen;" class="absolute shadow-md z-0 h-56 w-56 top-0 ml-0 mt-40 left-0 float-right rounded-full bg-x5 | lg:-ml-24" />

            <div class="z-20 flex items-center justify-start px-8">
                <div>
                    <div class="tracking-wide text-gray-100 mt-4 mb-12 lg:mb-0 text-3xl font-bold | lg:text-7xl lg:mt-14">Aptitudes</div>
                    <div class="h-4 lg:h-8 bg-x5 -mt-12 lg:mt-0 rounded w-full" />
                </div>
            </div>

            <div class="px-16 flex items-center justify-center mt-6 text-white flex-wrap | lg:mt-32 lg:px-12">
                <product-item title="Back-End" :price="2.99" image="planet" color="bg-pink-400" classes="-mt-10 w-1/3 lg:w-1/2" :items-count="store.cornDog" @remove="removeCornDog" @add="addCornDog">
                    <ul class="text-left space-y-2 list-disc" slot="description">
                        <li>PHP (Laravel)</li>
                        <li>PostgreSQL</li>
                        <li>C# (.NET, Unity)</li>
                    </ul>
                </product-item>
                <product-item title="Front-end" :price="2.59" image="rocket" color="bg-yellow-300" classes="-mt-10  w-1/3 lg:w-1/2" :items-count="store.miniCornDog" @remove="removeMiniCornDog" @add="addMiniCornDog">
                    <ul class="text-left space-y-2 list-disc" slot="description">
                        <li>Vue.js, Pinia</li>
                        <li>React</li>
                        <li>UI/UX Design</li>
                    </ul>
                </product-item>
                <product-item title="Extra" :price="4.99" image="astronaut" color="bg-blue-500" classes="w-1/4 lg:w-1/3 -mt-10" :items-count="store.hamburger" @remove="removeHamburger" @add="addHamburger">
                    <ul class="text-left space-y-2 list-disc" slot="description">
                        <li>AWS (SQS/SNS)</li>
                        <li>Docker, CI/CD</li>
                        <li>Elasticsearch</li>
                    </ul>
                </product-item>
            </div>

            <div class="flex items-center justify-center">
                <button class="py-4 px-16 rounded-full bg-white text-xl text-x5 transition duration-300 | hover:bg-gray-300" @click="openShop">Finaliser la commande</button>
            </div>
        </section>

        <section class="snap-section flex items-center justify-center relative z-40 overflow-hidden">
            <div class="rounded-lg h-2/3 flex items-center justify-center">
                <div data-scroll-speed="4" class="absolute ml-96 mt-56 bottom-0 left-0 z-0 h-12 w-12 rounded-full bg-x5" />
                <div data-scroll-speed="8" class="absolute -ml-32 bottom-0 left-0 mb-80 z-0 h-80 w-80 rounded-full bg-x5" />

                <div data-aos="fade-up" data-aos-duration="1000" class="sizzle-bg w-4/5 lg:w-full h-full border-2 shadow-xl border-black relative z-10">
                    <div class=" h-full flex items-center justify-center flex-col space-y-6 glass text-x5 text-xl p-6 px-2 lg:px-12">
                        <div class="font-bold mb-6 text-4xl | lg:text-8xl">Découvrez Sizzle</div>
                        <div>Un jeu mobile de type puzzle où tout les joueurs doivent trouver le mot du jour.</div>
                        <div class="pb-3">Déployez comme PWA, et maintenant sur le <span class="font-bold">google play store.</span></div>
                        <a class="py-4 px-16 rounded-full bg-white text-xl text-x5 transition duration-300 | hover:text-x2 hover:bg-x4" href="https://play.google.com/store/apps/details?id=sizzle.jonathancleary">Télécharger sur Play Store</a>
                    </div>
                </div>
            </div>
            <div class="absolute pointer-events-none right-0 bottom-0 lg:-mb-52 flex items-center justify-end">
                <img data-scroll-speed="5" src="@/assets/img/launch.png" class="w-1/3 lg:w-1/5 relative z-0" alt="">
            </div>
        </section>

        <section class="bg-white snap-section flex items-center justify-center text-x5">
            <div class="px-0 | lg:px-20 relative z-40">
                <div class="text-4xl pb-3 mb-8 | lg:text-7xl">Contactez-moi.</div>
                <a href="mailto:clearyjonathan99@gmail.com" class="text-2xl text-x3 | hover:text-blue-500">clearyjonathan99@gmail.com</a>
            </div>
        </section>
    </div>
</template>

<script>
import ProductItem from './products/ProductItem.vue';
import { useCounterStore } from '../Store/cart';

export default {
    setup() {
        const store = useCounterStore();
        return { store };
    },

    name: 'Header',

    components: {
        ProductItem,
    },

    props: {
        shopOpened: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            count: 0,
            isScrolled: false,
        };
    },
    computed: {
        isSmall() {
            return window.innerWidth < 600;
        },
    },
    methods: {
        initAOS() {
            const aosAnimation = document.querySelectorAll('[data-aos]');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        entry.target.classList.add('aos-animate');
                    } else {
                        entry.target.classList.remove('aos-animate');
                    }
                });
            });

            aosAnimation.forEach((aosObject) => {
                observer.observe(aosObject);
            });
        },

        removeCornDog() {
            this.store.removeCornDog();
        },

        removeMiniCornDog() {
            this.store.removeMiniCornDog();
        },

        removeHamburger() {
            this.store.removeHamburger();
        },

        addCornDog() {
            this.store.addCornDog();
        },

        addMiniCornDog() {
            this.store.addMiniCornDog();
        },

        addHamburger() {
            this.store.addHamburger();
        },

        openShop() {
            this.$emit('update:shop-opened', !this.shopOpened);
        },
    },

    mounted() {
        this.initAOS();

        const textWrapper = document.querySelector('.anim .letters');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        // eslint-disable-next-line no-undef
        anime.timeline({ loop: false })
            .add({
                targets: '.anim .letter',
                translateX: [40, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: 'easeOutExpo',
                duration: 3000,
                delay: (el, i) => 500 + 30 * i,
            });

        const textWrapper2 = document.querySelector('.anim2 .letters2');
        textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<span class='letter2'>$&</span>");

        // eslint-disable-next-line no-undef
        anime.timeline({ loop: false })
            .add({
                targets: '.anim2 .letter2',
                translateY: [100, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: 'easeOutExpo',
                duration: 1400,
                delay: (el, i) => 1000 + 30 * i,
            });
    },
};
</script>
<style>
.anim .text-wrapper, .anim2 .text-wrapper {
  position: relative;
  display: inline-block;
}

.anim .letter, .anim2 .letter2 {
  display: inline-block;
  line-height: 1em;
}

.glass {
  background-color: rgba(242, 242, 240, 0.8);
  backdrop-filter: blur(10px);
}

.cover-up {
  background-color: rgba(17, 17, 16, 0.91);
}

.sizzle-bg {
  background-image: url("../../src/assets/img/Sizzle.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.space-bg {
  background-image: url("../../src/assets/img/space.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.scrollplz {
  mix-blend-mode: screen;
  height: 108vh;
  margin-bottom: -80vh
}

.snap-main {
  width: 100vw;
  height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-snap-type: mandatory;

}

.snap-section {
  width: 100vw;
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

@media only screen and (max-width: 600px) {
  .scrollplz {
    mix-blend-mode: overlay;
    height: 112vh;
    margin-bottom: -80vh
  }
}

</style>
